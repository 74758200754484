.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 1s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  /*background-color: #282c34;*/
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.svg-container {
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  vertical-align: top;
  overflow: hidden;
}

/*.navbar {*/
/*  overflow: visible;*/
/*  background-color: #333;*/
/*  position: fixed; !* Set the navbar to fixed position *!*/
/*  top: 0; !* Position the navbar at the top of the page *!*/
/*  width: 100%; !* Full width *!*/
/*}*/

/*!* Links inside the navbar *!*/
/*.navbar a {*/
/*  float: left;*/
/*  display: block;*/
/*  color: #f2f2f2;*/
/*  text-align: center;*/
/*  padding: 14px 16px;*/
/*  text-decoration: none;*/
/*  width: 200px;*/
/*}*/

.dateRangeSelectContainer {

  width: 45%;
  margin: 0 auto;
  padding: 20px;
}

.fftChartContainer
{

}

.fftContainer
{
  margin: 0 auto;
  width: 70%;
  margin-top: 40px
}


.main
{
  margin-top: 00px;
}

.loginLabelText
{
  vertical-align: middle;
}

.logoutLabelText
{
  vertical-align: middle;
  text-decoration: underline;
}

.optionsLabelText
{
  vertical-align: middle;
  text-decoration: underline;
}

.center {
  margin: auto;
  width: 25%;
  padding: 10px;
}

.imagecenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}