/* Basic page layout for pages with tables */

.page {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 10px;
}

.container {
    max-width: inherit;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    padding-top: 15px;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 22px;
}

.createButton {
    display: flex;
    margin-bottom: 32px;
    margin-top: 7px;
    justify-content: flex-end;
}
