/* TODO: Get rid of these CSS styles, in favor of makeStyles(). They are
    currently being leveraged all over the app, which is unfortunate :( */

.flex-form .flex-button {
    width: 80%;
    margin: 8px;
    border-radius: 20px;
}

.flex-button .flex-progress {
    height: inherit;
}